/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

* {
  --mdc-typography-body1-font-size: 14px;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
}

.flex-grid {
  display: flex;
}

.col {
  flex: 1;
}

.col-9 {
  flex: 1.5;
}

.col-6 {
  flex: 1;
}

.col-3 {
  flex: 0.5;
}

.col-1 {
  flex: 0.17;
}

.col-0 {
  flex: 0;
}

.mdl-data-table thead th {
  /*background-color: #3f51b5;*/
  background: #e5e7ea;
  color: #171717;
}

.mdl-data-table .pagination-page-size {
  display: inline-block;
  font-size: 1.2em;
}

.mdl-data-table .pagination-label {
  cursor: pointer;
  display: inline-block;
  padding: 15px;
  font-size: 1.2em;
}

.mdl-data-table {
  margin-bottom: 25px;
}

.mat-mdc-snack-bar-container.error {
  --mdc-snackbar-container-color: #ff191a;
}

.mat-mdc-snack-bar-container.warning {
  --mdc-snackbar-container-color: #ff9f0a;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/**
 * After upgrade to Angular 15 we had issue with mat-select
 * fields not showing complete content, this rule help us
 */
.cdk-overlay-pane {
  min-width: fit-content;
}

.cdk-overlay-pane.lineup-dialog-container {
  min-width: auto !important;
}

/**
 * After upgrade to Angular 15
 * we want to keep white color for selectors and inputs
 */
.mat-mdc-form-field-focus-overlay {
  background-color: white;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}

.mdl-checkbox__label,
.mdl-radio__label {
  font-size: var(--mdc-typography-body1-font-size, 16px);
  margin-left: 4px;
}

.mdl-radio {
  font-size: var(--mdc-typography-body1-font-size, 16px);
}
